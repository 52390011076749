<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">User List</div>
						</div>
						<div class="flex flex-grow-1 align-items-center justify-content-end">
							<Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</div>
					<Divider></Divider>
                    <div class="grid">
                        <div class="col-12 md:col-3">
							<div class="field" >
								<label for="keyword">Keyword</label>
								<InputText
									class="w-full"
									id="keyword"
									v-model="keywordListValue"
									placeholder="Search any name"
								/>
							</div>
                        </div>
						<div class="col-12 md:col-3">
							<div class="field">
								<label for="contact">Contact</label>
								<InputText
									class="w-full"
									id="contact"
									v-model="contactValue"
									placeholder="Enter email or mobile number"
								/>
							</div>
						</div>
						<div class="col-12 md:col-3">
							<div class="field">
								<label for="role">Role</label>
								<Dropdown
									class="w-full"
									id="role"
									v-model="roleValue"
                                    :options="roles"
                                    optionLabel="name"
                                    optionValue="value"
									placeholder="Select user role"
								/>
							</div>
						</div>
						<div class="col-12 md:col-3 md:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<label for="clear"> </label>
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DataTable v-if="userList.data != ''" ref="dt" :value="userList.data" dataKey="id"  responsiveLayout="scroll">
					<Column field="id" header="ID" headerStyle="width:8%; min-width:4rem;">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							#00{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="image" header="Image" headerStyle="width:12%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Image</span>
							<Avatar v-if="slotProps.data.photo" :image="constant.imgUrl + slotProps.data.photo"  class="mr-2" size="xlarge" shape="circle" />
							<Avatar v-else icon="pi pi-user" class="mr-2" size="xlarge" shape="circle" />
						</template>
					</Column>
					<Column field="name" header="Name" headerStyle="width:22%; min-width:12rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="role" header="Role" headerStyle="width:12%; min-width:5rem;">
						<template #body="slotProps">
							<span class="p-column-title">Role</span>
							<span class="capitalize">{{formatCurrency(slotProps.data.role)}}</span>
						</template>
					</Column>
					<Column field="email" header="E-mail" headerStyle="width:16%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">E-mail</span>
							{{slotProps.data.email}}
						</template>
					</Column>
					<Column field="primary_contact" header="Contact No." headerStyle="width:18%; min-width:10rem;">
						<template #body="slotProps">
							<span v-if="slotProps.data.mobile">
								{{slotProps.data.mobile}}
							</span>
						</template>
					</Column>
					<Column field="action" header="Actions" headerStyle="width:12%; min-width:11rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
							<Button icon="pi pi-pencil" class="p-button-raised p-button-text p-button-rounded mr-2" @click="editOpen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-raised p-button-text p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="userList.from" :rows="userList.rows" :totalRecords="userList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No user found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="userDialog" :style="{width: '550px'}" :header="Header" :modal="true" class="p-fluid" :closable="false">
					<div class="mb-2 -mt-1 "><small>* <span class="text-orange-600">Required</span></small></div>
                    <div class="field">
                        <label for="role">Role <span>*</span></label>
                        <Dropdown
                            id="role"
                            v-model="valid$.role.$model"
                            :options="roles"
                            optionLabel="name"
                            optionValue="value"
                            placeholder="Select your role"
                            :class="{'p-invalid': valid$.role.$invalid && submitted}"
                        />
                        <span v-if="valid$.role.$error && submitted">
                            <span
                                id="role-error"
                                v-for="(error, index) of valid$.role.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Role")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.role.$invalid && submitted) || valid$.role.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.role.required.$message.replace("Value", "Role") }}
                        </small>
                    </div>

                    <div class="field" v-if="valid$.role.$model == 'manager'">
                        <label for="agency_id">Agency <span>*</span></label>
                        <Dropdown
                            id="agency_id"
                            v-model="valid$.agency_id.$model"
                            :options="agencyList"
                            optionLabel="name"
                            optionValue="id"
							:filter="true"
                            placeholder="Select an agency"
                            :class="{'p-invalid': valid$.agency_id.$invalid && submitted}"
                        />
                        <span v-if="valid$.agency_id.$error && submitted">
                            <span
                                id="agency_id-error"
                                v-for="(error, index) of valid$.agency_id.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Agency Id")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.agency_id.$invalid && submitted) || valid$.agency_id.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.agency_id.required.$message.replace("Value", "Agency Id") }}
                        </small>
                    </div>

					<div class="field">
						<label for="name">Name <span>*</span></label>
						<InputText
							id="name"
							v-model="valid$.name.$model"
							required="true"
							placeholder="Enter Name"
							:class="{'p-invalid': valid$.name.$invalid && submitted}"
						/>
						<span v-if="valid$.name.$error && submitted">
							<span
								id="name-error"
								v-for="(error, index) of valid$.name.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Name")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.name.$invalid && submitted) || valid$.name.$pending.$response"
							class="p-error"
						>
							{{ valid$.name.required.$message.replace("Value", "Name") }}
						</small>
					</div>

                    <div class="field">
                        <label for="mobile">Mobile <span>*</span></label>
                        <InputText
                            :class="{'p-invalid': valid$.mobile.$invalid && submitted}"
                            id="mobile"
                            v-model="valid$.mobile.$model"
                            placeholder="Enter mobile number"
                        />
                        <span v-if="valid$.mobile.$error && submitted">
                            <span
                                id="mobile-error"
                                v-for="(error, index) of valid$.mobile.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Mobile")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.mobile.$invalid && submitted) || valid$.mobile.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.mobile.required.$message.replace("Value", "Mobile") }}
                        </small>
                    </div>

                    <div class="field">
						<label for="email">E-mail <span>*</span></label>
						<InputText
							:class="{'p-invalid': valid$.email.$invalid && submitted}"
							id="email"
							v-model="valid$.email.$model"
							type="email"
							placeholder="Enter E-mail"
						/>
						<span v-if="valid$.email.$error && submitted">
							<span
								id="email-error"
								v-for="(error, index) of valid$.email.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "E-mail")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.email.$invalid && submitted) || valid$.email.$pending.$response"
							class="p-error"
						>
							{{ valid$.email.required.$message.replace("Value", "E-mail") }}
						</small>
					</div>

                    <div class="field">
                        <label for="password">Password <span v-if="!selectedItem">*</span></label>
                        <Password
                            :class="{'p-invalid': valid$.password.$invalid && submitted}"
                            id="password"
                            toggleMask
                            :feedback="false"
                            v-model="valid$.password.$model"
                        />
                        <span v-if="valid$.password.$error && submitted">
                            <span
                                id="password-error"
                                v-for="(error, index) of valid$.password.$errors"
                                :key="index"
                            >
                                <small class="p-error mr-2">{{error.$message.replace("Value", "Password")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.password.$invalid && submitted) || valid$.password.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.password.required.$message.replace("Value", "Password") }}
                        </small>
                    </div>

                    <div class="field">
                        <label for="nid">Date of Birth</label>
                        <Calendar
                            id="dob"
                            v-model="valid$.dob.$model"
                            dateFormat="mm-dd-yy"
                            :showIcon="true"
                            placeholder="Enter Date of Birth"
                        />
                    </div>

                    <div class="field">
                        <label for="nid">NID</label>
                        <InputText
                            id="nid"
                            v-model="valid$.nid.$model"
                            placeholder="Enter nid"
                        />
                    </div>

                    <div class="field">
                        <label for="passport">Passport</label>
                        <InputText
                            id="passport"
                            v-model="valid$.passport.$model"
                            placeholder="Enter passport"
                        />
                    </div>

                    <div class="field">
                        <label for="points">Points</label>
                        <InputText
                            id="points"
                            v-model="valid$.points.$model"
                            placeholder="Enter points"
                        />
                    </div>

                    <div class="field flex mt-1 pt-1">
						<div class="w-3 md:w-2">Verified:</div>
						<div class="w-full md:w-8 md:flex-order-0 flex-order-1">
							<InputSwitch
								id="verified"
								v-model="valid$.verified.$model"
							/>
						</div>
                    </div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button v-if="selectedItem != null" label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="Edit(!valid$.$invalid)" :loading="loading"/>
						<Button v-else label="Create" icon="pi pi-check" class="p-button p-button-raised" @click="saveUser(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '550px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedItem">Are you sure you want to delete <b>{{selectedItem.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-raised p-button-danger" @click="Delete" />
					</template>
				</Dialog>

				<Dialog v-model:visible="showDialog" :style="{width: '550px'}" header="User Details" :modal="true" :closable="false">
					<div class="surface-section">
						<ul class="list-none p-0 m-0">
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="grid">
									<div class="col-6 col-offset-3">
										<Image v-if="selectedItem.photo" :src="constant.imgUrl + selectedItem.photo" alt="Image" width="250" />
										<Image v-else src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" alt="Image" width="250" />
									</div>
								</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">#00{{selectedItem.id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Role</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 capitalize">{{selectedItem.role}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap" v-if="selectedItem.role == 'manager'">
								<div class="text-500 w-8 md:w-3 font-medium">Agency Id</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.agency_id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">E-mail</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.email}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Contact No</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.mobile}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Verified</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.verified ? 'Yes' : 'No'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Date of Birth</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.dob ? formatDate(selectedItem.dob) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">NID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.nid ? selectedItem.nid : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Passport</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.passport ? selectedItem.passport : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Points</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.points ? selectedItem.points : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Created At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.created_at ? formatTime(selectedItem.created_at) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Updated At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.updated_at ? formatTime(selectedItem.updated_at) : '-'}}</div>
							</li>
						</ul>
					</div>
					<template #footer>
						<Button label="Close" icon="pi pi-times" class="p-button-text" @click="showDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from 'vue';
import Axios from 'axios';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, minLength, maxLength, email, numeric } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import Constant from '../data/constant';

export default {
	setup() {
		const toast = useToast();
		const router = useRouter();
		const route = useRoute();
		const constant =  ref(new Constant());

		const loading = ref(false);
		const pageLoading = ref(true);
		const userDialog = ref(false);
		const deleteDialog = ref(false);
		const showDialog = ref(false);
		const submitted = ref(false);

		const userList = ref(null);
		const agencyList = ref(null);
		const selectedItem = ref(null);
		const filters = ref({});
		const keywordListValue = ref();
		const contactValue = ref();
		const roleValue = ref();
		const pageValue = ref(1);
		const paginationValue = ref(10);
		const Header = ref('Create User');

		const roles = ref([
            {value: 'manager', name: 'Manager', key: 'M'},
            {value: 'owner', name: 'Owner', key: 'O'},
            {value: 'user', name: 'User', key: 'U'}
        ]);


		const state = reactive({
            role: '',
            verified: false,
            agency_id: '',
            name: '',
            email: '',
            mobile: null,
            password: null,
            dob: '',
            nid: '',
            passport: '',
            points: '',
		});

		const rules = {
            role : { required },
            verified : { required },
            agency_id : {required: requiredIf(
				function() {
					return state.role == "manager";
				}
			)},
			// agency_id: {},
            name : { required, maxLength: maxLength(255), minLength: minLength(4) },
            email : { required, email },
            mobile : { required, numeric, maxLength: maxLength(13), minLength: minLength(8) },
            password : {required: requiredIf(
				function() {
					return selectedItem.value == null;
				}
			), minLength: minLength(6) },
            dob: {},
            nid: {},
            passport: {},
            points: {},
		};

		const valid$ = useVuelidate (rules, state);

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.keyword != null){
                keywordListValue.value = route.query.keyword;
            }
            if(route.query.contact != null){
                contactValue.value = route.query.contact;
            }
            if(route.query.role != null){
                roleValue.value = route.query.role;
            }
        };
        getActiveQuerys();

		//USER LIST
		const getUserlist = () =>{
			Axios
			.get("api/admin/user/list",{
				params: {
                    page: pageValue.value,
                    pagination: paginationValue.value,
                    keyword: keywordListValue.value,
					contact: contactValue.value,
					role: roleValue.value,
                }
			}
			)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					userList.value = res.data.data;
					userList.value.from = userList.value.from-1;
					userList.value.rows = paginationValue.value;

                    for (let item of userList.value.data) {
						item.mobile = item.mobile ? formatMobileNumber(item.mobile) : '';
                        if (item.verified == 0) {
                            item.verified = false;
                        }else if (item.verified == 1) {
                            item.verified = true;
                        }
                    }
				}
				else{
					console.log(res.data);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getUserlist();

		//AGENCY LIST
        const getAgencylist = () =>{
			Axios
			.get("api/admin/agency/list")
			.then((res) =>{
				if (res.data.response == "success") {
					agencyList.value = res.data.data.data;
				}
				else{
					console.log(res.data);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getAgencylist();


		//OTHER FUNCTIONS
		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};
		const formatCurrentDate = (rawDate) => {
            return new Date(rawDate);
        };

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };

		const formatCurrency = (value) => {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'BDT'});
			return;
		};
		const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };

		const openNew = () => {
			submitted.value = false;
			userDialog.value = true;
		};

		const hideDialog = () => {
			userDialog.value = false;
			submitted.value = false;
            selectedItem.value = null;
			Header.value = 'Create User'

            state.role = '';
            state.verified = false;
            state.agency_id = '';
            state.name = '';
            state.email = '';
            state.mobile = null;
            state.password = null;
            state.dob = '';
            state.nid = '';
            state.passport = '';
            state.points = '';
		};

		//CREATE
		const saveUser = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

            var VerifiedVal = 0;

            if (state.verified == false) {
                VerifiedVal = 0
            }else{
                VerifiedVal = 1
            }

			const formData1 = new FormData();

            formData1.append("role", state.role);
            formData1.append("verified", VerifiedVal);
            if(state.role == "manager") formData1.append("agency_id", state.agency_id);
            formData1.append("name", state.name);
            formData1.append("email", state.email);
            formData1.append("mobile", state.mobile);
            formData1.append("password", state.password);
            if(state.dob) formData1.append("dob", moment(state.dob).format("YYYY-MM-DD"));
            if(state.nid) formData1.append("nid", state.nid);
            if(state.passport) formData1.append("passport", state.passport);
            if(state.points) formData1.append("points", state.points);

			Axios
            .post("api/admin/user/create", formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					userDialog.value = false;
					getUserlist();
					state.role = '';
					state.verified = false;
					state.agency_id = '';
					state.name = '';
					state.email = '';
					state.mobile = null;
					state.password = '';
					state.dob = '';
					state.nid = '';
					state.passport = '';
					state.points = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data);
					userDialog.value = true;
                }
            })
            .catch((err) => {
                console.log(err);
                userDialog.value = true;
                showToast("error", "Error", err.message);
            });
		};

		//READ
		const confirmDetailSelected = (product) => {
			selectedItem.value = product;
			showDialog.value = true;
		};
		
		//DELETE
		const confirmDelete = (product) => {
			selectedItem.value = product;
			deleteDialog.value = true;
		};

		const Delete = () => {
			Axios
			.delete("api/admin/user/delete/"+selectedItem.value.id)
			.then(res => {
				if(res.data.response == "success"){
					showToast("success", "Success", res.data.message);
					getUserlist();
				}
				else{
					showToast("error", "Error", res.data.message);
				}
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			})
			deleteDialog.value = false;
		};

		//EDIT OR UPDATE
		const editOpen = (product) => {
			selectedItem.value = product;
			userDialog.value = true;
			Header.value = 'Edit User'

            state.role = selectedItem.value.role;
            state.verified = selectedItem.value.verified;
            state.agency_id = selectedItem.value.agency_id;
            state.name = selectedItem.value.name;
            state.email = selectedItem.value.email;
            state.mobile = selectedItem.value.mobile;
            if(selectedItem.value.dob)state.dob = formatCurrentDate(selectedItem.value.dob);
            if(selectedItem.value.nid)state.nid = selectedItem.value.nid;
            if(selectedItem.value.passport)state.passport = selectedItem.value.passport;
            if(selectedItem.value.points)state.points = selectedItem.value.points;
		};

		const Edit = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

            var VerifiedVal = 0;

            if (state.verified == false) {
                VerifiedVal = 0
            }else{
                VerifiedVal = 1
            }

			const formData1 = new FormData();

			formData1.append("role", state.role);
            formData1.append("verified", VerifiedVal);
            if(state.role == "manager") formData1.append("agency_id", state.agency_id);
            formData1.append("name", state.name);
            formData1.append("email", state.email);
            formData1.append("mobile", state.mobile);
            if(state.password) formData1.append("password", state.password);
            if(state.dob) formData1.append("dob", moment(state.dob).format("YYYY-MM-DD"));
            if(state.nid) formData1.append("nid", state.nid);
            if(state.passport) formData1.append("passport", state.passport);
            if(state.points) formData1.append("points", state.points);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/admin/user/update/${selectedItem.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
                    selectedItem.value = null;
					userDialog.value = false;
					getUserlist();
					state.role = '';
					state.verified = false;
					state.agency_id = '';
					state.name = '';
					state.email = '';
					state.mobile = null;
					state.password = '';
					state.dob = '';
					state.nid = '';
					state.passport = '';
					state.points = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                userDialog.value = true;
                showToast("error", "Error", err);
            });
		};

		//FILTER
		const getFilteredList = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(keywordListValue.value != null){
                queryParams.keyword = keywordListValue.value;
            }
            if(contactValue.value != null){
                queryParams.contact = contactValue.value;
            }
            if(roleValue.value != null){
                queryParams.role = roleValue.value;
            }

            router.replace({ name: 'user', query: { ...queryParams } });

            getUserlist();
        };

        const clearFilters = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            keywordListValue.value = null;
            contactValue.value = null;
            roleValue.value = null;
            router.replace();

            getUserlist();
        };

		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'user', query: { ...queryParams } });

            getUserlist();
		}

		return{
			pageLoading,
			constant,
			valid$,
            roles,
			userDialog,
			deleteDialog,
			showDialog,
			selectedItem,
			filters,
			submitted,
			userList,
			saveUser,
			loading,
			formatDate,
			formatCurrency,
			formatTime,
			confirmDelete,
			Delete,
			editOpen,
			Edit,
			openNew,
			hideDialog,
			keywordListValue,
			contactValue,
            roleValue,
			clearFilters,
			getFilteredList,
			confirmDetailSelected,
            agencyList,
			onPage,
			paginationValue,
			Header,
			state
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
